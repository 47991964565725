import { useEffect } from 'react';
import warning from '../../_util/warning';
const names = {};
export default function useFormWarning({ name }) {
    useEffect(() => {
        if (name) {
            names[name] = (names[name] || 0) + 1;
            warning(names[name] <= 1, 'Form', 'There exist multiple Form with same `name`.');
            return () => {
                names[name] -= 1;
            };
        }
    }, [name]);
}
